<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">门店列表</h3>
      <p class="mdall">
        目前拥有门店 <span style="color: #fff">{{ total }}</span> 家
      </p>
    </div>
    <div class="btnAry">
      <div class="top">
        <span class="addshop shou" @click="$router.push('/shop/shopAdd')"
          >添加门店 <i class="el-icon-circle-plus"></i
        ></span>
        <span
          class="addshop shou"
          style="background-color: #369bde; margin: 0 10px"
          @click="excelVisible = true"
          >导入门店 <i class="el-icon-folder-add"></i
        ></span>
      </div>
      <div class="bottom">
        <h6>
          筛选条件
          <strong>请根据输入框提示输入不同的筛选条件</strong>
        </h6>
        <span style="font-size: 14px; margin-left: 10px">门店名称:</span>
        <el-input v-model="from.name" placeholder="请输入门店名称"></el-input>

        <span style="font-size: 14px; margin-left: 10px">门店电话:</span>
        <el-input v-model="from.phone" placeholder="请输入门店电话"></el-input>

        <span style="font-size: 14px; margin-left: 10px">门店状态:</span>
        <div style="display: inline; margin-right: 100px; padding-left: 10px">
          <el-radio v-model="from.status" :label="0">全部</el-radio>
          <el-radio v-model="from.status" :label="1">营业中</el-radio>
          <el-radio v-model="from.status" :label="2">休息中</el-radio>
        </div>
        <!-- <span style="font-size: 14px; margin-left: 10px">店主姓名:</span
        ><el-input
          v-model="from.username"
          placeholder="请输入店主姓名"
        ></el-input>
        <span style="font-size: 14px; margin-left: 10px">店主电话:</span
        ><el-input
          v-model="from.userphone"
          placeholder="请输入店主电话"
        ></el-input> -->

        <span
          class="addshop shou"
          style="
            margin-left: 20px;
            padding: 5px 15px;
            background-color: #0970cc;
          "
          @click="shaixuan"
          >筛选</span
        >
        <span
          class="addshop shou"
          style="background-color: #fc5531; margin-left: 10px"
          @click="exportShop"
          >导出门店 <i class="el-icon-folder-opened"></i
        ></span>
      </div>
    </div>
    <el-table id="loading" :data="shoplist" stripe border>
      <!-- <el-table-column type="expand">
        <template slot-scope="props"> </template>
      </el-table-column> -->
      <el-table-column prop="id" label="门店id" width="80"> </el-table-column>
      <!-- <el-table-column label="门店所属" width="120">
        <template slot-scope="scope">
          {{scope.row.type == 1 ? '实体门店' : '虚拟门店'}}
        </template>
      </el-table-column> -->
      <el-table-column prop="typename" label="门店类型" width="120">
      </el-table-column>
      <!-- <el-table-column prop="name" label="门店归属人" width="160">
      </el-table-column> -->
      <el-table-column prop="name" label="门店名称" width="160">
      </el-table-column>
      <el-table-column prop="phone" label="门店电话" width="150">
      </el-table-column>
      <el-table-column label="区域">
        <div slot-scope="scope">
          {{ scope.row.province_name }}
          {{ scope.row.city_name }}
          {{ scope.row.district_name }}
        </div>
      </el-table-column>
      <el-table-column prop="address" label="详细地址"> </el-table-column>
      <el-table-column label="营业状态" width="150">
        <div slot-scope="scope">
          <span
            class="status"
            style="background-color: #1aad19"
            v-if="scope.row.status == 1"
            >营业中</span
          >
          <span
            class="status"
            style="background-color: #626365"
            v-if="scope.row.status == 2"
            >休息中</span
          >
          <strong
            class="setstatus shou"
            @click="setStatus(scope.row.id, scope.row.status)"
            >修改</strong
          >
        </div>
      </el-table-column>
      <el-table-column label="激活状态" width="100">
        <div slot-scope="scope">
          <span
            class="status"
            style="background-color: #1aad19"
            v-if="scope.row.active_state == 1"
            >已激活</span
          >
          <span
            class="status"
            style="background-color: #626365"
            v-if="scope.row.active_state == 2"
          >
            未激活</span
          >
          <!-- <strong
            class="setstatus shou"
			v-if="scope.row.active_state == 2"
            @click="setActiveState(scope.row.id, scope.row.active_state)"
            >修改</strong
          > -->
        </div>
      </el-table-column>
      <el-table-column label="操作" width="400">
        <div class="caozuo" slot-scope="scope">
          <div
            class="btnBJ shou"
            style="margin-right: 5px"
            @click="bianji(scope.row.id)"
          >
            编辑
          </div>
          <div
            class="btnBJ shou"
            style="background-color: #1dbf9e; margin-right: 5px"
            @click="detail(scope.row.id)"
          >
            详情
          </div>
          <div
            class="btnBJ shou"
            style="background-color: #1dbf9e; margin-right: 5px"
            @click="jiHuo(scope.row.id, scope.row.active_state)"
            v-if="scope.row.active_state == 2"
          >
            激活
          </div>
          <div
            class="btnBJ shou"
            style="background-color: #20222a; margin-right: 5px"
            @click="jiHuo(scope.row.id, scope.row.active_state)"
            v-if="scope.row.active_state == 1"
          >
            关闭
          </div>
          <div class="btnDel shou" @click="del(scope.row.id)">删除</div>
          <div
            class="btnDel shou"
            @click="sccode(scope.row.id, scope.row.user_id)"
            style="background-color: #369bde; margin: 0 5px"
          >
            生成分享码
          </div>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChg"
      @current-change="crtChg"
      :current-page="from.page"
      :page-sizes="[5, 10, 20]"
      :page-size="from.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 导入门店 -->
    <el-dialog title="文件上传" :visible.sync="excelVisible" width="40%" center>
      <!--  :show-file-list="false" 是否显示上传文件的list -->
      <!-- drag  是否可拖拽 -->
      <!--  multiple 是否上传多文件 -->
      <!--   :action="upUrl" 上传地址 -->
      <el-upload
        style="text-align: center"
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
        :http-request="uploadadSectionFile"
        drag
        action=""
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">仅限xls和xlsx文件</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="margin-right: 20px"
          size="mini"
          @click="excelVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="success" @click="downloadBTN"
          >下载模板</el-button
        >
        <a
          class="downloadBTN"
          target="_blank"
          href="https://adminbayj.qiannianjituan.com/uploads/store_demo.xlsx"
          style="display: none"
        ></a>
      </span>
    </el-dialog>

    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 展示二维码 -->
    <el-dialog title="门店二维码" :visible.sync="erweimaShow" width="25%">
      <div>
        <img :src="storeQrcode" alt="" style="display: block; margin: 0 auto" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="erweimaShow = false">取 消</el-button>
        <!-- <el-button type="primary" @click="exportShop2">确 定</el-button> -->
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import axios from "axios";
axios.interceptors.request.use((response) => {
  let token = window.localStorage.getItem("admin_token") || "";
  response.headers.AdminToken = token;
  return response;
});
import { showLoading, hideLoading } from "@/utils/loading.js";
import { filenameFormat } from "@/utils/index";
import {
  getshoplist,
  delShop,
  updataxls,
  exportData,
  setShopStatus,
  scStoreQrcode,
  outExcel,
  setActiveState,
} from "@/api/api.js";
export default {
  components: {},
  name: "shoplist",
  data() {
    return {
      // 筛选条件
      from: {
        status: 0, // 状态
        name: "", // 门店名
        phone: "", // 门店电话
        // username: "", // 店主名称
        // userphone: "", // 店主电话
        export_type: 1,
        export: "",
        page: 1, // 页码
        limit: 10, // 条数
      },
      total: 3,
      shoplist: [],
      //   模板下载
      // downUrl: "applet.qiannianjituan.com/uploads/store_demo.xlsx",
      // downUrl: "/uploads/store_demo.xlsx",
      excelVisible: false,
      url: null,
      exportShow: false,
      exportType: 1,
      erweimaShow: false,
      storeQrcode: "",
    };
  },
  methods: {
    async jiHuo(id, status) {
      const { data } = await setActiveState({
        id: id,
        status: status == 1 ? 2 : 1,
      });
      if (data.code != 200) return this.$message.warning(data.data);
      this.getlist();
    },
    // async setActiveState(id, active_state) {
    //   const { data } = await setActiveState({ id: id });
    //   if (data.code != 200) return this.$message.error(data.data);
    //   this.getlist();
    // },

    async setStatus(id, status) {
      let newStatus = status == 1 ? 2 : 1;
      const { data } = await setShopStatus({ store_id: id, status: newStatus });
      if (data.code != 200) return this.$message.error(data.data);
      this.shoplist.forEach((item) => {
        if (item.id == id) {
          item.status = newStatus;
        }
      });
      this.$message.success("修改状态成功!");
    },

    exportShop() {
      this.exportShow = true;
    },
    exportShows() {
      this.exportShow = false;
    },
    async sccode(id, userid) {
      const { data } = await scStoreQrcode({
        store_id: id,
        user_id: userid || "",
        path: "",
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.storeQrcode = data.data;
      this.erweimaShow = true;
      console.log(data.data);
    },
    async Excel() {
      let form = {
        page: this.from.page,
        limit: this.from.limit,
        name: this.from.name,
        phone: this.from.userphone,
        status: this.from.status,
        type: this.exportType,
      };
      const { data, textStatus, request } = await outExcel(form);
      let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
      hideLoading();

      // let filename = decodeURI(
      //   request.getResponseHeader("content-disposition").split("=")[1]
      // );
      // console.log(new String(filename.getBytes("iso8859-1"), "UTF-8"))
      let objectUrl = URL.createObjectURL(blob); //生成下载链接
      let a = document.createElement("a"); //创建a标签用于文件下载
      a.href = objectUrl; //赋值下载路径
      a.download = filenameFormat(
        request.getResponseHeader("content-disposition")
      );  //下载的文件名称（非必填）
      document.body.appendChild(a); //插入DOM树
      a.click(); //点击a标签触发
      document.body.removeChild(a); //删除a标签
    },

    // 导出xls
    exportShop2() {
      showLoading();
      // let form = {
      //   page: this.from.page,
      //   limit: this.from.limit,
      //   name: this.from.name,
      //   phone: this.from.userphone,
      //   status: this.from.status,
      //   type: this.exportType,
      // };
      this.from.export = "export";
      this.from.export_type = this.exportType;
      this.getlist();
      this.from.export = "";
      // this.Excel()
      // ,headers:{'content-type':'form-data'}
      // axios.post("https://adminbayj.qiannianjituan.com/backend/store/outExcel",form,{ responseType: "blob" }).then((res) => {
      //     const data = res.data;
      //     const url = window.URL.createObjectURL(
      //       new Blob([data], { type: "application/vnd.ms-excel"})
      //     );
      //     const a = document.createElement("a");
      //     a.style.display = "none";
      //     a.href = url;
      //     a.download = this.fileName();
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a);
      //     hideLoading();
      //     this.exportShow = false;
      //   }).catch((err) => {
      //     hideLoading();
      //     this.exportShow = false;
      //     console.log(err.message);
      //   });
    },

    fileName() {
      let date = new Date();
      let time = this.$timeTo.time3(this.$timeTo.time4(date));
      return `门店列表-${time}`;
    },

    async uploadadSectionFile(params) {
      let form = new FormData();
      form.append("file", params.file);
      console.log('form===',form)
      const { data } = await updataxls(form);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
      this.excelVisible = false;
    },

    //上传之前校验的方法
    handleBeforeUpload(file) {
      // 校验
      let legalName = ["xlsx", "xls"];
      // 拿到后缀名
      let name = file.name.substring(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      if (legalName.includes(name)) {
        console.log(legalName.includes(name));
      } else {
        this.$message.info("文件格式不对，仅限xls和xlsx");
        return false;
      }
    },

    detail(id) {
      this.$router.push({
        path: "/shop/detail",
        query: {
          id,
        },
      });
    },

    downloadBTN() {
      showLoading();
      let a = document.querySelector(".downloadBTN");
      a.download = this.fileName();
      console.log(a);
      a.click();
      hideLoading();
      this.exportShow = false;
    },

    setuserlist(id) {
      this.$router.push({
        path: "/shop/userlist",
        query: { id },
      });
    },

    setrolelist(id) {
      console.log(id);
    },

    shaixuan() {
      this.from.page = 1;
      this.from.limit = 10;
      this.getlist();
    },

    async getlist() {
      // alert('3')
      if (this.from.export) {
        let { data, types, request } = await getshoplist(this.from, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        hideLoading();
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1])
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        );  //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
      } else {
        let { data } = await getshoplist(this.from, "");
        if (data.code != 200) return false;
        console.log(data, "datadatadata");
        this.shoplist = data.data.data;
        this.total = data.data.total;
      }
    },

    sizeChg(e) {
      console.log(e);
      this.from.limit = e;
      this.getlist();
    },

    crtChg(e) {
      console.log(e);
      this.from.page = e;
      this.getlist();
    },

    bianji(id) {
      this.$router.push({ path: "/shop/shopAdd", query: { id } });
    },

    del(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delShop({ store_id: id });
          if (data.code != 200)
            return this.$message.error("操作失败请稍后重试");
          this.shoplist.forEach((item) => {
            if (item.id == id) {
              let index = this.shoplist.indexOf(item);
              console.log(index);
              this.shoplist.splice(index, 1);
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  // mounted(){
  //  alert('44')
  //  this.getlist();
  // },
  created() {
    // alert('33')
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.box-card {
  margin: 10px;

  .btnAry {
    padding: 8px 50px 30px 50px;
    .top {
      padding: 10px 0;
      margin: 0 0 20px 0;
    }
    .bottom {
      // background-color: pink;
      h6 {
        font-size: 16px;
        font-weight: normal;
        padding: 10px 0 0 10px;
        strong {
          font-weight: normal;
          font-size: 12px;
          color: #21a0ff;
        }
      }
      .el-input {
        width: 300px;
        margin: 10px;
        .el-input__inner {
          height: 32px;
        }
      }
    }
    .addshop {
      background-color: #37d6b7;
      padding: 5px 10px;
      color: #f1f1f1;
      border-radius: 3px;
      transition: font-size 0.2s;
    }
  }
  .seh {
    width: 320px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #22a1ff;
    .search {
      height: 30px;
      flex: 1;
      border: 0;
    }
    .sehBtn {
      color: #f1f1f1;
      width: 50px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #22a1ff;
    }
  }
  .mdall {
    margin-top: 10px;
    padding: 2px 0;
    background-image: linear-gradient(to right, #1abe9c, #38d7b7);
    font-size: 16px;
    color: #f1f1f1;
  }
  .el-table {
    margin-bottom: 30px;
  }
}
.btnl {
  color: #fff;
  padding: 1px 5px;
  border-radius: 2px;
  background-color: #24d5b2;
  &:nth-child(2) {
    background-color: #17b6ff;
  }
}
.status {
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
  margin-right: 15px;
}
.setstatus {
  border-bottom: 1px solid #999;
  padding: 1px 2px;
  margin-left: 5px;
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid #111;
    color: #111;
  }
}
/deep/.el-pagination {
  margin-top: 20px;
}
/deep/.el-card__body {
  padding-top: 0;
}
/deep/.el-table__body {
  min-width: 500px;
  background-color: pink;
}
</style>